<template>
  <v-container fluid>
    <v-card :loading="loading">
      <template slot="progress">
        <v-progress-linear color="red" height="5" indeterminate></v-progress-linear>
      </template>
      <v-toolbar color="indigo darken-3" dark dense flat>
        <v-toolbar-title>
          <v-icon>
            mdi-check
          </v-icon>
          รายการคำสั่งรอการอนุมัติ
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-select
          :items="types"
          label="ค้นหาจาก"
          style="width:40px; !important"
          v-model="type"
          hide-details
          single-line
          dense
          solo-inverted
        ></v-select>
        <v-text-field
          prepend-inner-icon="mdi-magnify"
          width="200px"
          single-line
          hide-details
          dense
          solo-inverted
          label="กรอกคำค้นหา"
          clearable
          v-model="keyword"
          @keypress.enter="getList"
        ></v-text-field>
      </v-toolbar>
      <v-card-text> </v-card-text>
      <command-table
        :keyword="keyword"
        :type="type"
        ref="ctable"
        @show-pdf="showPdf"
        @approve="approve"
        @not-approve="notApprove"
      ></command-table>
    </v-card>

    <!-- Snack bar -->
    <v-snackbar :timeout="2000" v-model="showAlert" absolute centered :color="snackColor">
      {{ message }}
    </v-snackbar>

    <!-- dialog -->
    <bjp-confirm-dialog ref="confirm" />

    <!-- pdf dialog -->
    <pdf-dialog ref="pdf"></pdf-dialog>
  </v-container>
</template>

<script>
import commandTable from './components/commandTable'
import { update, getPdf } from '@/api/command_approval'
import pdfDialog from '@/components/pdfDialog'
import { mapActions } from 'vuex'
export default {
  components: {
    commandTable,
    pdfDialog,
  },
  data() {
    return {
      keyword: '',
      type: 'number',
      types: [{ text: 'เลขคำสั่ง', value: 'number' }],
      // snackbar
      showAlert: false,
      snackColor: 'primary',
      message: '',
      loading: false,
      reason: '',
      remark: '',
    }
  },
  methods: {
    getList() {
      this.$refs.ctable.getList()
    },
    async showPdf(id) {
      this.loading = true
      try {
        const pdfBase64 = await getPdf(id)
        this.$refs.pdf.open(pdfBase64.pdf)
      } catch (error) {
        this.$toast.error({
          message: error.response.data.message,
          title: 'Error',
        })
      } finally {
        this.loading = false
      }
    },
    approve(id) {
      this.loading = true
      let data = {
        id: id,
        approval: 1,
        reason: this.reason,
        remark: this.remark,
      }
      update(data)
        .then(res => {
          this.getList()
        })
        .catch(err => {
          this.$toast.error({
            message: err.response.data.message,
            title: 'Error',
          })
        })
        .finally(() => {
          this.loading = false
        })
    },
    notApprove(id) {
      this.loading = true
      let data = {
        id: id,
        approval: 1,
        reason: this.reason,
        remark: this.remark,
      }
      update(data)
        .then(res => {
          this.getList()
        })
        .catch(err => {
          this.$toast.error({
            message: err.response.data.message,
            title: 'Error',
          })
        })
        .finally(() => {
          this.loading = false
        })
    },
    showMessage(msg, color) {
      if (color == undefined) color = 'primary'
      this.snackColor = color
      this.message = msg
      this.showAlert = true
    },
    ...mapActions('Appfilter', ['getCommandApproval']),
  },
  created() {
    this.getCommandApproval()
  },
}
</script>

<style></style>
